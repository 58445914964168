import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import Link from '../shared/link'
import Button from '../shared/button'
import { mainHeadingStyles } from '../shared/type'

const Container = styled.div`
  margin-bottom: ${p => p.theme.space[12]};

  ${mediaQueries.lg} {
    max-width: 38.5rem;
    min-width: 33rem;
    margin: 0;
  }
`

const Title = styled.h2`
  margin: ${p => `0 0 ${p.theme.space[6]}`};
  ${mainHeadingStyles}
`

const Content = styled.p`
  margin: ${p => `0 0 ${p.theme.space[8]}`};

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[10]}`};
    max-width: 33.125rem;
  }
`

const StartButton = styled(withAttrs(Button, { size: 'large', as: Link }))`
  width: 100%;
  padding: ${p => `${p.theme.space[4]} ${p.theme.space[7]}`};
  font-size: ${p => p.theme.fontSizes[2]};

  ${mediaQueries.lg} {
    width: auto;
    padding: ${p => `${p.theme.space[4]} ${p.theme.space[7]}`};
    font-size: ${p => p.theme.fontSizes[2]};
  }
`

const Cta = () => (
  <Container>
    <Title>Join creators worldwide</Title>
    <Content>
      Grow your online business without a website. Generate leads, tag your
      subscribers, send promo emails, and build automations.
    </Content>
    <StartButton to="/start/">Get started</StartButton>
  </Container>
)

export default Cta
