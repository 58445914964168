import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import backgroundMobile from 'assets/images/home/footer/world-mobile.jpg'
import backgroundDesktop from 'assets/images/home/footer/world–desktop.jpg'
import Container from '../shared/container'
import FeatureList from './feature-list'
import Cta from './cta'

const OuterContainer = styled.section`
  background-color: ${p => p.theme.colors.cta.background};
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top 0px left;
  padding: ${p => `${p.theme.space[12]} 0`};

  ${mediaQueries.lg} {
    padding: ${p => `${p.theme.space[26]} 0`};
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    background-size: 1092px 720px;
    background-position: center top -30px;
    background-size: contain;
  }
`

const InnerContainer = styled(Container)`
  display: flex;
  flex-flow: column;

  ${mediaQueries.lg} {
    flex-flow: row;
    justify-content: space-between;
    gap: 4rem;
  }
`

const FooterCta = ({ className }) => (
  <OuterContainer className={className}>
    <InnerContainer>
      <Cta />
      <FeatureList />
    </InnerContainer>
  </OuterContainer>
)

export default FooterCta
