import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'

const Icon = styled.svg`
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 1rem;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: ${p => p.theme.fontSizes[0]};

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[1]};
    text-align: left;
    align-items: flex-start;
  }

  ${mediaQueries.xl} {
    font-size: ${p => p.theme.fontSizes[2]};
  }

  :nth-of-type(1) ${Icon} {
    fill: #4084d3;
  }

  :nth-of-type(2) ${Icon} {
    fill: #fb8765;
  }

  :nth-of-type(3) ${Icon} {
    fill: #886add;
  }

  :nth-of-type(4) ${Icon} {
    fill: #1ba97d;
  }
`

const Feature = ({ icon, children }) => (
  <Container>
    <Icon as={icon} />
    {children}
  </Container>
)

export default Feature
