import React from 'react'
import styled from '@emotion/styled'
import { FormsIcon, ContactsIcon, AutomationsIcon } from 'assets/icons/emails'
import { EmailIcon } from 'assets/icons/social'
import { mediaQueries } from '../../theme'
import Feature from './feature'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: ${p => p.theme.space[6]};
  width: 100%;
  justify-content: space-between;

  ${mediaQueries.lg} {
    max-width: 28.25rem;
    grid-gap: ${p => `${p.theme.space[16]} ${p.theme.space[5]}`};
  }
`

const Title = styled.span`
  margin-bottom: ${p => p.theme.space[2]};
  font-size: ${p => p.theme.fontSizes[5]};
  line-height: 1.2;
  font-weight: ${p => p.theme.fontWeights.bold};

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[9]};
  }
`

const FeatureList = () => (
  <Container>
    <Feature icon={FormsIcon}>
      <Title>650K</Title>
      Forms displayed
      <br />
      this week
    </Feature>
    <Feature icon={ContactsIcon}>
      <Title>30K</Title>
      Contacts collected
      <br />
      this week
    </Feature>
    <Feature icon={AutomationsIcon}>
      <Title>5K</Title>
      Automations runs
      <br />
      this week
    </Feature>
    <Feature icon={EmailIcon}>
      <Title>15K</Title>
      Broadcasts sent
      <br />
      this week
    </Feature>
  </Container>
)

export default FeatureList
